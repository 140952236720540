
document.addEventListener('DOMContentLoaded', function () {
  cookieconsent.run({"notice_banner_type":"simple","consent_type":"implied","palette":"light","language":"de","website_name":"uglyshark.de","change_preferences_selector":"Cookie-Einstellungen ändern","cookies_policy_url":"https://www.uglyshark.de/datenschutz.html#m134"});
  });
  

$(window).scroll(function() {
    inViewport();
  });
  $(window).resize(function() {
    inViewport();
  });
  
  function inViewport() {
    // einfach die klasse des Elementes das animiert werden soll
    $('.picture').each(function() {
      var divPos = $(this).offset().top,
        topOfWindow = $(window).scrollTop();
      if (divPos < topOfWindow + 400) {
        $(this).addClass('show');
        // Wenn sich die Animation wiederholen soll:
        // } else {
        //  $(this).removeClass('sichtbar');
      }
    });
  }

var hamburger = document.querySelector(".hamburger");
hamburger.onclick = function() {
    this.classList.toggle("checked");
}

new AnimOnScroll( document.getElementById( 'grid' ), {
    minDuration : 0.4,
    maxDuration : 0.7,
    viewportFactor : 0.2
} );

